import React, { Component } from 'react';
import Cabecalho from '../componentes/Cabecalho';
import Rodape from '../componentes/Rodape';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import InputMask from 'react-input-mask';
import TextField from '@material-ui/core/TextField';
import { id_firebase } from '../content-site';
import { firestore } from './../firebase-config';

class Contato extends Component {

    constructor(props) {
        super(props);

        this.state = {
            assunto: '',
            nome: '',
            email: '',
            telefone: '',
            mensagem: '',
            enviado: false,
            msg_enviado: '',
            revenda: {},
        }
        this.mounted = false;

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

    }

    componentDidMount() {

        window.scrollTo(0, 0);

        this.mounted = true;

        firestore.collection("revenda").doc(id_firebase)
            .get()
            .then(doc => {
                if (doc.exists) {
                    this.setState({
                        revenda: doc.data(),
                    });
                }
            }).catch(function (error) {
            });
    }

    handleChange(event) {

        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleSubmit(event) {

        event.preventDefault();

        this.setState({ enviado: true, msg_enviado: 'Enviando mensagem...' });

        var mensagem = "Nome: " + this.state.nome + "<br>E-mail: " + this.state.email + "<br>Telefone: " + this.state.telefone + "<br>Mensagem: " + this.state.mensagem;

        fetch('https://us-central1-intermedio-1513801146022.cloudfunctions.net/enviarEmail', {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify({
                remetente: "Contato " + this.state.revenda.penome_fantasia + "<" + this.state.revenda.peemail + ">",
                assunto: this.state.assunto,
                destinatarios: this.state.revenda.peemail,
                corpo: mensagem,
                corpoHtml: mensagem
            })
        })
            .then(data => {
                this.setState({
                    enviado: true,
                    msg_enviado: 'E-Mail Enviado com Sucesso',
                    nome: "",
                    assunto: "",
                    email: "",
                    telefone: "",
                    mensagem: "",
                });
                setInterval(() => this.setState({ enviado: false }), 5000);
            })
            .catch(error => {
                this.setState({ enviado: true, msg_enviado: 'Erro ao enviar e-mail, tentar novamente mais tarde' });
                setInterval(() => this.setState({ enviado: false }), 5000);
            });

    }


    render() {
        return (
            <div>
                <Cabecalho revenda={this.state.revenda} />

                <section className="inner-intro bg-1 bg-overlay-black-70">
                    <div className="container">
                        <div className="row text-center intro-title">
                            <div className="col-md-12 text-md-left d-inline-block">
                                <h1 className="text-white">CONTATE-NOS</h1>
                            </div>
                        </div>
                    </div>
                </section>


                <section className="contact page-section-ptb white-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="section-title">
                                    <span>Entre em contato sempre que precisar.</span>
                                    <h2>O seu feedback irá nos ajudar muito a aprimorar nossos serviços.</h2>
                                    <div className="separator"></div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-3 col-md-6">
                                <div className="contact-box text-center">
                                    <i className="fa fa-map-marker"></i>
                                    <h5>ENDEREÇO</h5>
                                    <p>Praça Santana, 29 - Centro, Patos de Minas - MG, CEP 38700-048</p>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                                <div className="contact-box text-center">
                                    <i className="fa fa-phone"></i>
                                    <h5>TELEFONE</h5>
                                    <p> (34) 3823 - 7048 </p>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                                <div className="contact-box text-center">
                                    <i className="fa fa-envelope-o"></i>
                                    <h5>Email</h5>
                                    <p>contato@meloveiculospatos.com.br</p>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                                <div className="contact-box text-center">
                                    <i className="fa fa-whatsapp"></i>
                                    <h5>Whatsapp</h5>
                                    <p>(34) 9 9715-7472</p>
                                </div>
                            </div>
                        </div>
                        <div className="page-section-ptb">
                            <div className="row">
                                <div className="col-lg-8 col-sm-12">
                                    <div className="gray-form">
                                        <div id="formmessage" className="form-notice" style={{ display: "none" }}>A mensagem de sucesso / erro vai aqui</div>
                                        <ValidatorForm id="contactForm" className="s-form wow zoomInUp" ref="form" onSubmit={this.handleSubmit} >
                                            <TextValidator
                                                label="ASSUNTO *"
                                                onChange={this.handleChange}
                                                name="assunto"
                                                value={this.state.assunto}
                                                className="form-ui-bootstrap"
                                                validators={['required']}
                                                errorMessages={['Preenchimento obrigatório']}
                                            />
                                            <TextValidator
                                                label="NOME *"
                                                onChange={this.handleChange}
                                                name="nome"
                                                value={this.state.nome}
                                                className="form-ui-bootstrap"
                                                validators={['required']}
                                                errorMessages={['Preenchimento obrigatório']}
                                            />
                                            <TextValidator
                                                label="E-MAIL *"
                                                onChange={this.handleChange}
                                                name="email"
                                                value={this.state.email}
                                                className="form-ui-bootstrap"
                                                validators={['required', 'isEmail']}
                                                errorMessages={['Preenchimento obrigatório', 'E-mail inválido']}
                                            />
                                            <InputMask
                                                mask="(99) 99999-9999"
                                                value={this.state.telefone}
                                                label="TELEFONE *"
                                                name="telefone"
                                                onChange={this.handleChange}
                                            >
                                                {() => <TextField
                                                    label="TELEFONE *"
                                                    name="telefone"
                                                    type="text"
                                                    className="form-ui-bootstrap"
                                                />}
                                            </InputMask>
                                            <br />
                                            <br />
                                            <textarea name="mensagem" placeholder="MENSAGEM" onChange={this.handleChange} ></textarea>
                                            <button type="submit" className="btn m-btn">ENVIAR MENSAGEM<span className="fa fa-angle-right"></span></button>
                                            {
                                                this.state.enviado &&
                                                <blockquote>
                                                    <p><em>{this.state.msg_enviado}</em></p>
                                                </blockquote>
                                            }
                                        </ValidatorForm>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-12 mt-lg-0 mt-4">
                                    <div className="opening-hours gray-bg mt-sm-0">
                                        <h6>Horário de funcionamento</h6>
                                        <ul className="list-style-none">
                                            <li><strong>Domingo</strong> <span className="text-red"> Fechado</span></li>
                                            <li><strong>Quarta </strong> <span> 07:30 ás 18:00 </span></li>
                                            <li><strong>Segunda</strong> <span> 07:30 ás 18:00 </span></li>
                                            <li><strong>Terça </strong>  <span> 07:30 ás 18:00 </span></li>
                                            <li><strong>Quinta </strong> <span> 07:30 ás 18:00 </span></li>
                                            <li><strong>Sexta </strong>  <span> 07:30 ás 18:00 </span></li>
                                            <li><strong>Sababo </strong> <span> 07:30 ás 12:00 </span></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4">
                                <div className="feature-box-3">
                                    <div className="icon">
                                        <i className="fa fa-clock-o"></i>
                                    </div>
                                    <div className="content">
                                        <h6>HORÁRIO DE FUNCIONAMENTO</h6>
                                        <p>Atendimento ao cliente não é uma técnica a ser implantada, mas uma postura a ser cultivada. </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="feature-box-3">
                                    <div className="icon">
                                        <i className="fa fa-support"></i>
                                    </div>
                                    <div className="content">
                                        <h6>NOSSO CENTRO DE SUPORTE</h6>
                                        <p>Atendimento em redes sociais, whatsapp e sites para maior conforto de nosso clientes. </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="feature-box-3">
                                    <div className="icon">
                                        <i className="fa fa-info"></i>
                                    </div>
                                    <div className="content">
                                        <h6>ALGUMA INFORMAÇÃO </h6>
                                        <p>Caso tenha duvidas ou sujestões entre em contato com nossa equipe.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="contact-map">
                    <div className="container-fluid">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3781.710647446086!2d-46.5107267!3d-18.5870794!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ae8b6f869b1099%3A0x6f9b1de475303f50!2sMelo%20Ve%C3%ADculos!5e0!3m2!1spt-BR!2sbr!4v1667592325632!5m2!1spt-BR!2sbr"></iframe>
                    </div>
                </section>

                <Rodape revenda={this.state.revenda} />

            </div>
        )
    }
}

export default Contato;